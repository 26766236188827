<template>
    <div class="mainbox">
        <div>
            <img src="../../assets/v4Success.png" alt="">
            <p>报告生成中，请稍候…</p>
            <el-button v-if="$route.query && $route.query.redirect_uri" @click="goback">返回</el-button>
        </div>
    </div>
</template>
<script>

export default {
    mounted() {
        if(this.$route.query && this.$route.query.redirect_uri){
            setTimeout(() => {
                window.location.href = this.$route.query.redirect_uri
                
                }, 3000);
            }
    },
    methods: {
        goback() {
            if(this.$route.query && this.$route.query.redirect_uri){
                window.location.href = this.$route.query.redirect_uri
            }else{
                this.$router.go(-1);
            }
        },
    },
};
</script>
<style scoped lang="stylus">
.mainbox{
    text-align: center;
    margin: 0 auto;
    margin-top: 3.09rem;
    div{
        text-align: center;
    }
    img{
        width: 2.18rem;
        height: 2.18rem;
        margin-bottom:0.72rem;
    }
    p{
        font-family: PingFang SC;
        color: #1D2129;
        font-weight: 600;
        font-size: 16px;
        margin-bottom:0.54rem;

    }
    >>>.el-button{
        width: 200px;
        height: 40px;
        top: 470.5px;
        left: 2142.5px;
        border-radius: 4px;
        background-color: #165DFF;
        color: #FFFFFF;
        
    }
}
</style>