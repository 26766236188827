<template>

    <div class="verify_code_box flex">
        <!-- 真输入框隐藏，点击底下的假输入框就获取焦点 -->
        <input id="verifyInput" class="input_box" v-model="data.verifyCodeTotal" inputMode="numeric" @input="inputFunc"
            @focus="focusFunc" @blur="blurFunc" @keydown.left="handleLeft" @keydown.right="handleRight">
        <div v-for="i in 6" :key="i" class="flex-aic-jcc bottom_line box_item"
            :class="{ 'mgrt-34': i != 6, 'is_fill': data.verifyCodeTotal.length >= i }" @click="focusInputFunc(i)">
            <span>{{data.verifyCodeTotal?.substring(i - 1, i) }}</span>
            <!-- 假的光标 -->
            <div class="selection" v-show="data.isFocus && data.inputFocusIndex == i"></div>
            <div class="selection_none" v-show="!data.isFocus || data.inputFocusIndex != i"></div>
        </div>
    </div>


</template>
<script>

export default {
    data() {
        return {
            data:{
                inputFocusIndex:'',
                verifyCodeTotal:'',
                isFocus:false
            },
        }
    },
    mounted() {
    },
    methods: {
        focusInputFunc(index) {
            const _input = document.getElementById(`verifyInput`)
            _input.focus()
            _input.setSelectionRange(index, index)
            this.data.inputFocusIndex = index
        },

        inputFunc() {

            this.data.verifyCodeTotal = this.data.verifyCodeTotal.replace(/[^0-9]/g, '')?.slice(0, 6);
            const _input = document.getElementById(`verifyInput`)
            if (this.data.verifyCodeTotal.length == 6) {
                _input.setSelectionRange(this.data.verifyCodeTotal.length, this.data.verifyCodeTotal.length)
            }
            this.data.inputFocusIndex = _input.selectionStart
            this.$emit('inputChange', this.data.verifyCodeTotal.length)

            if (this.data.inputFocusIndex > this.data.verifyCodeTotal.length) {
                this.data.inputFocusIndex = this.data.verifyCodeTotal.length
            }
            if (this.data.inputFocusIndex > 6) {
                this.data.inputFocusIndex = 6
            }
            if (this.data.inputFocusIndex < 1) {
                this.data.inputFocusIndex = 1
                _input.setSelectionRange(1, 1)
            }
        },

        focusFunc() {
            this.data.isFocus = true
            const _input = document.getElementById(`verifyInput`)
            this.data.inputFocusIndex = _input.selectionStart
            if (this.data.inputFocusIndex > this.data.verifyCodeTotal.length) {
                this.data.inputFocusIndex = this.data.verifyCodeTotal.length
            }
            if (this.data.inputFocusIndex > 6) {
                this.data.inputFocusIndex = 6
            }
            if (this.data.inputFocusIndex < 1) {
                this.data.inputFocusIndex = 1
                _input.setSelectionRange(1, 1)
            }

        },

        blurFunc() {
            this.data.isFocus = false
        },

        handleLeft() {
            _input = document.getElementById(`verifyInput`)
            this.data.inputFocusIndex--;
            if (this.data.inputFocusIndex < 1) {
                this.data.inputFocusIndex = 1
                setTimeout(() => {
                    _input.setSelectionRange(1, 1)
                }, 1)
            }
        },

        handleRight() {
            this.data.inputFocusIndex++;
            if (this.data.inputFocusIndex > 6) {
                this.data.inputFocusIndex = 6
            }
        },

    }
}
</script>
<style scoped lang="stylus">

@keyframes blink {
    0% { opacity: 1; }
    50% { opacity: 0; }
    100% { opacity: 1; }
  }
.flex{
    display: flex;
}
.verify_code_box {
	position: relative;
	margin: 0.72rem 0rem 0 0rem;
    padding-bottom: .72rem;

	.input_box{
		position: absolute;
		caret-color: transparent;
		z-index: -10;
		width: 100%;
		font-weight: 500;
		font-size: .42rem;
		color: transparent;
		background: transparent;
		line-height: .58rem;
		border: none;
	}
    .box_item+.box_item{
        margin-left:0.09rem;
    }
	.box_item{
		height: .68rem;
		text-align: center;
		width: 0.9rem;
		font-size: .42rem;
		color: #12241F;
		line-height: .58rem;
        position :relative;
		.selection{
            position: absolute;
            right 0;
            top: 4px;
			border-right: .02rem solid #003300; 
			height: .48rem;
			margin: .03rem 0;
			animation:blink 1s infinite;//1s值一秒内完成动画
			-webkit-animation:blink 1s infinite; /* Safari and Chrome */
		}
		.selection_none{
			border-right: .02rem solid transparent; 
		}
	}
	.bottom_line {
		border-bottom: .02rem solid #DFDFE0;
	}
	.is_fill {
		// padding-bottom: .11rem;
		//border-bottom: .04rem solid #35CBCF
        
	}
}

</style>