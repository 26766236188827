import {
  getPurchasedVersions
} from "@/api/api.js";
import router from "@/router";
import store from "@/store";

/**
 * 征期转化成名字
 * 比如2018004转化成2018年
 **/
export const zq2name = zq => {
  if (!zq) {
    return "";
  }
  const lastChar = zq.split("").pop();
  const year = zq.slice(0, 4);
  let ret = null;

  if (lastChar === "1") {
    //月
    const month = parseInt(zq.slice(4, 6));
    ret = `${year}年${month}月`;
  } else if (lastChar === "2") {
    //季
    const quarter = parseInt(zq.slice(4, 6));
    ret = `${year}年第${quarter}季度`;
  } else if (lastChar === "4") {
    //年
    ret = `${year}年`;
  }
  return ret;
};
/**
 * 征期转化为信息对象
 *
 */
export const zq2obj = zq => {
  const lastChar = zq.split("").pop();
  const year = zq.slice(0, 4);
  let ret = {
    type: null,
    value: null
  };

  ret.type = parseInt(lastChar);
  if (lastChar === "1") {
    //月
    const month = parseInt(zq.slice(4, 6));
    ret.value = new Date(`${year}-${month}`);
  } else if (lastChar === "2") {
    const quarter = parseInt(zq.slice(4, 6));
    //季
    ret.value = {
      year: year,
      quanter: quarter
    };
  } else if (lastChar === "4") {
    //年
    ret.value = year;
  }
  return ret;
};
/**
 * 上个征期
 * @param {*} data
 */
/**
 * 上个征期
 * @param {*} data
 */
export const previousZq = zq => {
  let zqLast = zq.substr(zq.length - 1, 1);

  let nextZq = "";
  if (zqLast == 4) {
    let year = zq.slice(0, -3);
    let rest = zq.slice(-3);
    year--;
    nextZq = `${year}${rest}`;

    return nextZq;
  }

  if (zqLast == 1) {
    let year = zq.slice(0, -3);
    let month = zq.slice(4, 6);
    if (month == "01") {
      month = "12";
      year--;
    } else {
      month = (Number(month) - 1).toString();
    }

    if (month.length == 1) {
      month = "0" + month
    }
    nextZq = `${year}${month}1`;

    return nextZq;
  }

  if (zqLast == 2) {
    let year = zq.slice(0, 4);
    let quer = zq.slice(5, 6);
    let nextQuer;
    let nextYear;
    if (quer == 1) {
      nextYear = year - 1;
      nextQuer = 4;
    } else {
      nextYear = year;
      nextQuer = quer - 1;
    }
    nextQuer = "0" + nextQuer;
    nextZq = `${nextYear}${nextQuer}2`;

    return nextZq;
  }
};


export const smoothChartNumber = data => {
  data = {
    ...data
  };
  for (let [key, value] of Object.entries(data)) {
    if (1 == 1 || Number(value) === value) {
      let absUsed = false;

      if (value < 0) {
        value = Math.abs(value);
        absUsed = true;
      }

      let i = Math.log10(value);

      if (i > 0) {
        i = i + 1;
      } else {
        i = 0;
      }

      const p = Math.pow(10, Math.abs(i));

      let outValue;
      if (absUsed) {
        outValue = -i - value / p;
        if (outValue >= -0.1 && outValue < 0) {
          outValue = -0.1;
        }
      } else {
        outValue = i + value / p;
        if (outValue <= 0.1 && outValue > 0) {
          outValue = 0.1;
        }
      }

      data[key] = outValue;
    }
  }

  return data;
};

//返回最大的已经购买的产品版本 3:普通 4:专业 5:至尊

export async function getHighestVersionPurchased() {
  let token = JSON.parse(localStorage.getItem("token")).token;
  let res, purchasedVersions, defaultVersion;
  try {
    res = await getPurchasedVersions({
      token
    });
    purchasedVersions = res.data.list;
    defaultVersion = res.data.default;
  } catch (error) {
    throw error;
  }
  let theHighestVersion = 0;
  let versionArr = res.data.list;

  Object.entries(purchasedVersions).forEach(([version, isPurchased]) => {
    if (theHighestVersion == null) {
      theHighestVersion = version;
    } else if (isPurchased && theHighestVersion < version) {
      theHighestVersion = version;
    }
  });
  return {
    theHighestVersion,
    defaultVersion: defaultVersion,
    versionArr
  };
}

/**
 * 得到报告路由
 * @param name
 * @param params
 * @param query
 * @returns {{query, name: string, params}}
 */
export function getReportRoute(name,params,query){
  console.log($route)
  return {
    name: 'h5-result-share',
    params: {
      ...params
    },
    query: {
      ...query
    },
  }
}

/**
 * 自动跳转到对应的检测报告详情(弃用)
 */
export function getAutoRoutePushTo(uuid, $route, isSimple, query, isMobile,isBoss) {
  console.log(uuid, $route, isSimple, query, isMobile);
  if (isMobile) {
     if ($route.name.includes("result-head") && isBoss) {
      return {
        name: 'boss-watch-report',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    }else if ($route.name.includes("share")) {
      return {
        name: 'h5-result-share',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    } else if ($route.name.includes("always")) {
      return {
        name: 'h5-result-always',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    }  else {
      return {
        name: 'h5-result',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    }
  } else if (sessionStorage.getItem("isFromAdmin")) {
    return {
      name: 'resultFromAdmin',
      params: {
        uuid: uuid
      },
      query: {
        // bankId: $route.query.bankid,
        zq: $route.query.zqId,
        uuid: $route.query.uuid,
        nsrsbh: $route.query.nsrsbh,
        desensitization: $route.query.desensitization,
        ...query
        //name: $route.query.bankMc,
      },
    }
    return
  }
  if (isSimple == 0) {
    if (isBoss) {
      return {
        name: 'boss-watch-report',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    }else if ($route.name.includes("share")) {
      return {
        name: 'result-share',
        params: {
          uuid: uuid
        },
        query: {
          //bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
          //name: $route.query.bankMc,
        },
      }
    } else if ($route.name.includes("always")) {
      return {
        name: 'always-result',
        params: {
          uuid: uuid
        },
        query: {
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...query
        }
      }
    } else {
      return {
        name: 'result',
        params: {
          uuid: uuid
        },
        query: {
          // bankId: $route.query.bankid,
          zq: $route.query.zqId,
          uuid: $route.query.uuid,
          nsrsbh: $route.query.nsrsbh,
          desensitization: $route.query.desensitization,
          ...$route.query
          //name: $route.query.bankMc,
        },
      }
    }

  } else {
    return {
      name: 'SimpleResultEditor',
      params: {
        action: 'edit',
        uuid: uuid,
        nsrsbh: $route.query.nsrsbh,
      },
      query: {
        bankId: $route.query.bankid,
        zq: $route.query.zqId,
        desensitization: $route.query.desensitization,
        ...query
      },
    }
  }
}

/**
 * 自动跳转到对应的报告中间页面配置
 */
export function getAutoMiddlePageToConfig(uuid, type, $route, query) {
  if ($route.name.includes("share")) {
    return {
      name: 'result-head-middle-page-share',
      params: {
        uuid: uuid,
        type
      },
      query: {
        desensitization: $route.query.desensitization,
        ...query
      }
    }
  } else if ($route.name.includes("always")) {
    return {
      name: 'always-result-head-middle-page',
      params: {
        uuid: uuid,
        type
      },
      query: {
        desensitization: $route.query.desensitization,
        ...query
      }
    }
  } else {
    return {
      name: 'result-head-middle-page',
      params: {
        uuid: uuid,
        type
      },
      query: {
        desensitization: $route.query.desensitization,
        ...query
      }
    }
  }
}

/**
 * echart x轴换行
 * @param value
 * @param maxLength
 * @returns {string|*}
 */
export function axisLabelFormatter(value, maxLength = 5) {
  let ret = ""; //拼接加\n返回的类目项
  const valLength = value.length; //X轴类目项的文字个数
  const rowN = Math.ceil(valLength / maxLength); //类目项需要换行的行数
  if (rowN > 1) //如果类目项的文字大于5,
  {
    for (var i = 0; i < rowN; i++) {
      var temp = ""; //每次截取的字符串
      var start = i * maxLength; //开始截取的位置
      var end = start + maxLength; //结束截取的位置
      //这里也可以加一个是否是最后一行的判断，但是不加也没有影响，那就不加吧
      temp = value.substring(start, end) + "\n";
      ret += temp; //凭借最终的字符串
    }
    return ret;
  } else {
    return value;
  }
}

//是否是脱敏的uuid
export function isDesensitizationUuid(uuid) {
  const desensitizationUuid = "9a5e2a72f8644d08b0b09e3b08d345b4";
  return uuid == desensitizationUuid && router.currentRoute.path.includes("always")
}

export function getDesensitizationFromRouter() {
  let desensitization = router.currentRoute.query.desensitization
  desensitization = parseInt(desensitization);
  return desensitization ? 1 : 0
}

export const previousZq2 = zq => {
  let zqLast = zq.substr(zq.length - 1, 1);;

  let nextZq = "";
  if (zqLast == 4) {
    let year = zq.slice(0, -3);
    let rest = zq.slice(-3);
    year = year - 2;
    nextZq = `${year}${rest}`;

    return nextZq;
  }

  if (zqLast == 1) {
    let year = zq.slice(0, -3);
    let month = zq.slice(4, 6);
    if (month == "01") {
      month = "11";
      year--;
    } else if (month == "02") {
      month = "12";
      year--;
    } else {
      month = (Number(month) - 2).toString();
    }

    if (month.length == 1) {
      month = "0" + month
    }
    nextZq = `${year}${month}1`;

    return nextZq;
  }

  if (zqLast == 2) {
    let year = zq.slice(0, 4);
    let quer = zq.slice(5, 6);
    let nextQuer;
    let nextYear;
    if (quer == 1) {
      nextYear = year - 1;
      nextQuer = 3;
    } else if (quer == 2) {
      nextYear = year - 1;
      nextQuer = 4;
    } else {
      nextYear = year;
      nextQuer = quer - 2;
    }
    nextQuer = "0" + nextQuer;
    nextZq = `${nextYear}${nextQuer}2`;

    return nextZq;
  }
};

export function logoutAndClear($store){
  const customization = $store.state.customization;
  const contactUs = $store.state.contactUs;
  const appid_3rd = localStorage.getItem("appid_3rd");
  const wx_logo = localStorage.getItem("wx_logo");
  const index_logo = localStorage.getItem("wx_logo");

  $store.commit("reset");
  $store.cache.clear();
  $store.commit("clearToken");
  localStorage.clear();
  sessionStorage.clear();

  localStorage.setItem('appid_3rd',appid_3rd)
  localStorage.setItem("wx_logo", wx_logo);
  localStorage.setItem("index_logo", index_logo);
  $store.commit('setCustomization', {customization})
  $store.commit("setContactUs",{data:contactUs})
}
export function isJSON(str) {
  try {
     JSON.parse(str);
     return true;
  } catch (e) {
     return false;
  }
}