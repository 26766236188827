import HeaderAsideLayout from "@/layouts/HeaderAsideLayout";
import NotFound from "@/pages/NotFound";
import Dashboard from "@/pages/Dashboard";
import homePage from "@/pages/homePage/home";
import MyTest from "@/pages/Test/MyTest";
import TTest from "@/pages/TTest/TTest";
import SimpleResultEditor from "@/pages/SimpleResultEditor/SimpleResultEditor";
// pdf结果编辑器详情
import ResultDetail from "@/pages/SimpleResultEditor/components/ResultDetail";

import TestLog from "@/pages/Test/TestLog";
import PdfResult from "@/pages/PdfResult/PdfResult";

import ChooseTable from "@/pages/Test/ChooseTable";
import Invoice from "@/pages/Test/Invoice";
import InvoiceUpload from "@/pages/Test/invoiceUpload/invoiceUpload";
import UploadTable from "@/pages/Test/UploadTable";

import ManualTableList from "@/pages/Test/ManualTableList";

const ManualFill = () =>
  import("@/pages/Test/ManualFill");

const ManualFillBill = () =>
  import("@/pages/Result/ThreeYearResult/bill/ManualFill/ManualFill");

//登录注册
import Login from "@/pages/login/Login";
import loginBase from "@/pages/login/components/LoginBase";
import registerBase from "@/pages/login/components/registerBase";
import wxlogin from "@/pages/login/components/wxlogin";
//检测报告
import TestReports from "@/pages/Test/TestReports/testReports";
//用户设置
import UserSet from "@/pages/user/SetUser";
import setUserCompany from "@/pages/user/setUserCompany";
//企业认证
import companyApply from "@/pages/companyApply/companyApply";
// 小工具
import abnormal_tax from "@/pages/util/abnormal_tax";
import calculator_tax from "@/pages/util/calculator_tax";
import individual_tax from "@/pages/util/individual_tax";
import abnormal_msg from "@/pages/util/normalmsg";
// 小工具
import toolsAudit from "@/pages/tools/audit";
import authorization from "@/pages/tools/authorization";
import analysisSuccess from "@/pages/tools/success"
import toolsAuditPWD from "@/pages/tools/auditPwd";
//消息中心
import message from "@/pages/message/message";
//帮助中心
import helpCenter from "@/pages/help/help";
//忘记密码
import forgetPassword from "@/pages/login/components/forgetPassword";

// 产品中心
import productsDiscount from "@/pages/product/discount";
import productsOrder from "@/pages/product/record";
import productsGoods from "@/pages/product/goods";
import productsSubmit from "@/pages/product/submit";
import productsPay from "@/pages/product/pay";
import productsInfo from "@/pages/product/info";
import productsAgent from "@/pages/product/agent";
import goodsInfo from "@/pages/product/goodsInfo";
import paySuccess from "@/pages/product/paySuccess";

//申报表

import Balance from "@/pages/Test/UploadTable/Balance";

import shandong from "@/pages/shandong/index";


//第三方登录
import thirdPartAuth from "@/pages/3rdPartAuth";
import thirdPartRegister from "@/pages/3rdPartAuth/3rdPartRegister";

// H5报告
import H5FinancialAnalysis from "@/pages/Result/H5FinancialAnalysis/FinancialAnalysis";
import purchaseAndSaleType from "@/pages/Result/H5FinancialAnalysis/purchaseAndSaleType";
import purchaseType from "@/pages/Result/H5FinancialAnalysis/purchaseAndSaleType/buy";
import purSaleType from "@/pages/Result/H5FinancialAnalysis/purchaseAndSaleType/sale";

import upDownCustomerCompany from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerList/company";
import upDownCustomerCustomer from "@/pages/Result/H5FinancialAnalysis/UpDownCustomerList/customer";
// 横屏二级页面
import keyFinancialDataHp from "@/pages/Result/H5FinancialAnalysis/HP/keyFinancialDataHp";
import upDownG6 from "@/pages/Result/H5FinancialAnalysis/HP/upDownG6";
import purchaseHp from "@/pages/Result/H5FinancialAnalysis/HP/purchaseHp";
import financeAnalyzeHp from "@/pages/Result/H5FinancialAnalysis/HP/financeAnalyzeHp";
import dubangHp from "@/pages/Result/H5FinancialAnalysis/HP/dubangHp";
// 上下游风险企业横屏
import companyHp from "@/pages/Result/H5FinancialAnalysis/HP/companyHp";
import chinaAreaHp from "@/pages/Result/H5FinancialAnalysis/HP/chinaAreaHp";
import buySaleH5 from "@/pages/Result/H5FinancialAnalysis/HP/buySaleH5";
import buyHp from "@/pages/Result/H5FinancialAnalysis/HP/buyHp";
// 横屏二级页面

// 生成出错
import noData from "@/pages/Result/noData";
import FinanceAnalysis from "../pages/Result/bossWatchReport/financeAnalysis/FinanceAnalysis.vue";
import TaxRiskAnalysis from "../pages/Result/bossWatchReport/TaxRiskAnalysis/TaxRiskAnalysis.vue";
import SpecialInvoiceDetail
from "../pages/Result/bossWatchReport/specialInvoinceDetail/SpecialInvoiceDetail.vue";
import ReportDetails
from "../pages/Result/bossWatchReport/ReportDetails/ReportDetails.vue";
import IndustryComparison
from "../pages/Result/bossWatchReport/IndustryComparison/IndustryComparison.vue";
import OneKeyWatchInvoice
from "../pages/Result/bossWatchReport/OneKeyWatchInvoice/OneKeyWatchInvoice.vue";
import TaxAnalysis from "../pages/Result/bossWatchReport/TaxAnalysis/TaxAnalysis.vue";

const routerConfig = [
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
      path: "/",
      component: homePage,
      name: "homePage"
    }]
  },
  {
    path: "/reportLost",
    component:() =>import("@/pages/oem/reportLost.vue"),
    name: "reportLost"
    
  },
  // {
  //   path: "/userOnly/h5/result/result/:uuid",
  //   component: () =>
  //     import ("@/pages/H5/Result/Result"),
  //   name: "h5-result",
  //   props: true
  // },
  {
    path: "/shandong",
    component: shandong,
    name: "shandong"
  },
  {
    path: "/always",
    component: () =>
      import("@/pages/Result/Always"),
    props: true,
    name: 'always',
    children: [{
        path: "detect/:uuid",
        component: () =>
          import("@/pages/Result/Detect"),
        name: "detect-always",
        hidden: true,
      }, {
        path: "h5/result/:uuid",
        component: () =>
          import("@/pages/H5/Result/Result"),
        name: "h5-result-always",
        props: true
      },
      {
        path: "result-head/:uuid",
        component: () =>
          import("@/pages/Result/ResultHead"),
        name: "result-head-always",
        props: true
      },
      {
        path: "result-head-middle-page/:uuid/:type",
        component: () =>
          import("@/pages/Result/ResultHeadMiddlePage"),
        name: "always-result-head-middle-page",
        props: true
      },
      {
        path: "always-result/:uuid",
        component: () =>
          import("@/pages/Result/Result"),
        name: "always-result",
        props: true
      },
    ]
  },
  {
    path: "/manual-fill-table-bill",
    name: "manual-fill-table-bill",
    component: ManualFillBill,
    props: true
  },
  {
    path: "/userOnly",
    component: () =>
      import("@/pages/Result/UserOnly"),
    props: true,
    children: [{
        path: "detect/:uuid",
        component: () =>
          import("@/pages/Result/Detect"),
        name: "detect",
        hidden: true,
      },
      // 老板看报告
      {
        path: "boss-watch-report/:uuid",
        component: () => import("@/pages/Result/bossWatchReport/BossWatchReport"),
        name: "boss-watch-report",
        props: true,
        hidden: true,
      },
      {
        path: "boss-watch-report/:uuid/finance-analysis",
        component: FinanceAnalysis,
        name: "financeAnalysis",
        hidden: true,
        props: true,
      }, {
        path: "boss-watch-report/:uuid/industry-comparison",
        component: IndustryComparison,
        name: "IndustryComparison",
        hidden: true,
        props: true,
      }, {
        path: "boss-watch-report/:uuid/one-key-watch-invoice",
        component: OneKeyWatchInvoice,
        name: "OneKeyWatchInvoice",
        hidden: true,
        props: true,
      }, {
        path: "boss-watch-report/:uuid/tax-risk-analysis",
        component: TaxRiskAnalysis,
        name: "TaxRiskAnalysis",
        hidden: true,
        props: true,
      }, {
        path: "boss-watch-report/:uuid/tax-analysis",
        component: TaxAnalysis,
        name: "TaxAnalysis",
        hidden: true,
        props: true,
      }, {
        path: "boss-watch-report/:uuid/special-invoice-detail/:txType",
        component: SpecialInvoiceDetail,
        name: "SpecialInvoiceDetail",
        hidden: true,
        props: true,
      },
      {
        path: "boss-watch-report/:uuid/report-details/:name",
        component: ReportDetails,
        name: "ReportDetails",
        hidden: true,
        props: true,
      },
      // H5报告
      {
        path: "H5ThreeYearResult/:uuid",
        component: H5FinancialAnalysis,
        name: "H5FinancialAnalysis",
        hidden: true,
      },
      // 横屏二级页面
      {
        path: "keyFinancialDataHp/:uuid",
        component: keyFinancialDataHp,
        name: "keyFinancialDataHp",
        props: true
      },
      {
        path: "upDownG6/:uuid",
        component: upDownG6,
        name: "upDownG6",
        props: true
      },
      {
        path: "purchaseHp/:uuid/:taxNo/:zq",
        component: purchaseHp,
        name: "purchaseHp",
        props: true
      },
      {
        path: "financeAnalyzeHp/:uuid/",
        component: financeAnalyzeHp,
        name: "financeAnalyzeHp",
        props: true
      },
      {
        path: "dubangHp/:uuid/",
        component: dubangHp,
        name: "dubangHp",
        props: true
      },
      {
        path: "companyHp/:uuid/",
        component: companyHp,
        name: "companyHp",
        props: true
      },
      {
        path: "chinaAreaHp/:uuid/",
        component: chinaAreaHp,
        name: "chinaAreaHp",
        props: true
      },
      {
        path: "buySaleH5/:uuid/",
        component: buySaleH5,
        name: "buySaleH5",
        props: true
      },
      {
        path: "buyHp/:uuid/",
        component: buyHp,
        name: "buyHp",
        props: true
      },
      // 横屏二级页面
      // 二级页面跳转
      {
        path: "purchaseAndSaleType/:uuid",
        component: purchaseAndSaleType,
        name: "purchaseAndSaleType",
        props: true
      },
      {
        path: "purchaseType/:uuid",
        component: purchaseType,
        name: "purchaseType",
        props: true
      },
      {
        path: "purSaleType/:uuid",
        component: purSaleType,
        name: "purSaleType",
        props: true
      },
      {
        path: "upDownCustomerCompany/:uuid",
        component: upDownCustomerCompany,
        name: "upDownCustomerCompany",
        props: true
      },
      {
        path: "upDownCustomerCustomer/:uuid",
        component: upDownCustomerCustomer,
        name: "upDownCustomerCustomer",
        props: true
      },
      // 二级页面跳转
      // H5报告
      {
        path: "ThreeYearResult/:uuid",
        component: () =>
          import("@/pages/Result/ThreeYearResult/ThreeYearResult"),
        name: "ThreeYearResult",
        hidden: true,
      },
      {
        path: "jinzhiReport/:uuid",
        component: () =>
          import("@/pages/Result/JinzhiReport/JinzhiReport"),
        name: "JinzhiReport",
        hidden: true,
      },
      {
        path: "h5/result/result/:uuid/:style?",
        component: () =>
          import("@/pages/H5/Result/Result"),
        name: "h5-result",
        props: true
      },
      {
        path: "h5/result/:uuid/:style?",
        component: () =>
          import("@/pages/H5/Result/Result"),
        name: "h5-result",
        props: true
      }, {
        // path: "result",
        // component: HeaderAsideLayout,
        // hidden: true,
        // children: [{
        path: "result/:uuid/:style?",
        component: () =>
          import("@/pages/Result/Result"),
        name: "result",
        props: true
        // }, ]
      },
      {
        path: "result-head/:uuid",
        component: () =>
          import("@/pages/Result/ResultHead"),
        name: "result-head",
        props: true
      },
      {
        path: "result-head-middle-page/:uuid/:type",
        component: () =>
          import("@/pages/Result/ResultHeadMiddlePage"),
        name: "result-head-middle-page",
        props: true
      },
      {
        path: "resultFromAdmin/:uuid",
        component: () =>
          import("@/pages/Result/Result"),
        name: "resultFromAdmin",
        props: true,
        hidden: true
      },
    ]
  },
  {
    path: "/share/:uuid/:checkId",
    component: () =>
      import("@/pages/Result/ResultShare"),
    name: 'share',
    props: true,
    children: [
      // H5报告
      {
        path: "H5ThreeYearResult/:uuid",
        component: H5FinancialAnalysis,
        name: "H5FinancialAnalysis-share",
        hidden: true,
      },
      {
        path: "detect/:uuid",
        component: () =>
          import("@/pages/Result/Detect"),
        name: "detect-share",
        hidden: true,
      },
      {
        path: "ThreeYearResult/:uuid",
        component: () =>
          import("@/pages/Result/ThreeYearResult/ThreeYearResult"),
        name: "ThreeYearResult-share",
        hidden: true,
      }, {
        path: "jinzhiReport",
        component: () =>
          import("@/pages/Result/JinzhiReport/JinzhiReport"),
        name: "JinzhiReport-share",
        hidden: true,
      },
      //以下两个相同.都是报告的h5详情
      {
        path: "h5/result/:uuid",
        component: () => import("@/pages/H5/Result/Result"),
        name: "h5-result-2-share",
        props: true
      },
      {
        path: "h5-result",
        component: () =>
          import("@/pages/H5/Result/Result"),
        name: "h5-result-share",
        props: true
      },

      {
        path: "result-share",
        component: () =>
          import("@/pages/Result/Result"),
        name: "result-share",
        props: true
      },
      {
        path: "result-head",
        component: () =>
          import("@/pages/Result/ResultHead"),
        name: "result-head-share",
        props: true
      },
      {
        path: "result-head-middle-page-share/:type",
        component: () =>
          import("@/pages/Result/ResultHeadMiddlePage"),
        name: "result-head-middle-page-share",
        props: true
      },
      // 二级
      {
        path: "purchaseAndSaleType/:uuid",
        component: purchaseAndSaleType,
        name: "purchaseAndSaleType-share",
      },
      {
        path: "purchaseType/:uuid",
        component: purchaseType,
        name: "purchaseType-share"
      },
      {
        path: "purSaleType/:uuid",
        component: purSaleType,
        name: "purSaleType-share"
      },
      {
        path: "upDownCustomerCompany/:uuid",
        component: upDownCustomerCompany,
        name: "upDownCustomerCompany-share"
      },
      {
        path: "upDownCustomerCustomer/:uuid",
        component: upDownCustomerCustomer,
        name: "upDownCustomerCustomer-share"
      },
      // 横屏二级share
      {
        path: "keyFinancialDataHp/:uuid",
        component: keyFinancialDataHp,
        name: "keyFinancialDataHp-share",
        props: true
      },
      {
        path: "upDownG6/:uuid",
        component: upDownG6,
        name: "upDownG6-share",
        props: true
      },
      {
        path: "purchaseHp/:uuid/:taxNo/:zq",
        component: purchaseHp,
        name: "purchaseHp-share",
        props: true
      },
      {
        path: "financeAnalyzeHp/:uuid",
        component: financeAnalyzeHp,
        name: "financeAnalyzeHp-share",
        props: true
      },
      {
        path: "dubangHp/:uuid",
        component: dubangHp,
        name: "dubangHp-share",
        props: true
      },
      {
        path: "companyHp/:uuid/",
        component: companyHp,
        name: "companyHp-share",
        props: true
      },
      {
        path: "chinaAreaHp/:uuid/",
        component: chinaAreaHp,
        name: "chinaAreaHp-share",
        props: true
      },
      {
        path: "buySaleH5/:uuid/",
        component: buySaleH5,
        name: "buySaleH5-share",
        props: true
      },
      {
        path: "buyHp/:uuid/",
        component: buyHp,
        name: "buyHp-share",
        props: true
      },
    ]
  },
  {
    path: "/share-boss/:uuid/:checkId",
    component: () =>
      import("@/pages/Result/ResultShareBoss"),
    name: 'share-boss',
    props: true,
    children: [
      // H5报告
      {
        path: "H5ThreeYearResult/:uuid",
        component: H5FinancialAnalysis,
        name: "H5FinancialAnalysis-share",
        hidden: true,
      },
      {
        path: "detect/:uuid",
        component: () =>
          import("@/pages/Result/Detect"),
        name: "detect-share",
        hidden: true,
      },
      {
        path: "ThreeYearResult/:uuid",
        component: () =>
          import("@/pages/Result/ThreeYearResult/ThreeYearResult"),
        name: "ThreeYearResult-share",
        hidden: true,
      }, {
        path: "jinzhiReport",
        component: () =>
          import("@/pages/Result/JinzhiReport/JinzhiReport"),
        name: "JinzhiReport-share",
        hidden: true,
      },
      //以下两个相同.都是报告的h5详情
      {
        path: "h5/result/:uuid",
        component: () => import("@/pages/H5/Result/Result"),
        name: "h5-result-share2",
        props: true
      },
      {
        path: "h5-result",
        component: () =>
          import("@/pages/H5/Result/Result"),
        name: "h5-result-share",
        props: true
      },

      {
        path: "result-share",
        component: () =>
          import("@/pages/Result/Result"),
        name: "result-share",
        props: true
      },
      {
        path: "result-head",
        component: () =>
          import("@/pages/Result/ResultHead"),
        name: "result-head-share2",
        props: true
      },
      {
        path: "result-head-middle-page-share/:type",
        component: () =>
          import("@/pages/Result/ResultHeadMiddlePage"),
        name: "result-head-middle-page-share",
        props: true
      },
      // 二级
      {
        path: "purchaseAndSaleType/:uuid",
        component: purchaseAndSaleType,
        name: "purchaseAndSaleType-share",
      },
      {
        path: "purchaseType/:uuid",
        component: purchaseType,
        name: "purchaseType-share"
      },
      {
        path: "purSaleType/:uuid",
        component: purSaleType,
        name: "purSaleType-share"
      },
      {
        path: "upDownCustomerCompany/:uuid",
        component: upDownCustomerCompany,
        name: "upDownCustomerCompany-share"
      },
      {
        path: "upDownCustomerCustomer/:uuid",
        component: upDownCustomerCustomer,
        name: "upDownCustomerCustomer-share"
      },
      // 横屏二级share
      {
        path: "keyFinancialDataHp/:uuid",
        component: keyFinancialDataHp,
        name: "keyFinancialDataHp-share",
        props: true
      },
      {
        path: "upDownG6/:uuid",
        component: upDownG6,
        name: "upDownG6-share",
        props: true
      },
      {
        path: "purchaseHp/:uuid/:taxNo/:zq",
        component: purchaseHp,
        name: "purchaseHp-share",
        props: true
      },
      {
        path: "financeAnalyzeHp/:uuid",
        component: financeAnalyzeHp,
        name: "financeAnalyzeHp-share",
        props: true
      },
      {
        path: "dubangHp/:uuid",
        component: dubangHp,
        name: "dubangHp-share",
        props: true
      },
      {
        path: "companyHp/:uuid/",
        component: companyHp,
        name: "companyHp-share",
        props: true
      },
      {
        path: "chinaAreaHp/:uuid/",
        component: chinaAreaHp,
        name: "chinaAreaHp-share",
        props: true
      },
      {
        path: "buySaleH5/:uuid/",
        component: buySaleH5,
        name: "buySaleH5-share",
        props: true
      },
      {
        path: "buyHp/:uuid/",
        component: buyHp,
        name: "buyHp-share",
        props: true
      },
    ]
  },
  // 小工具
  {
    path: "/tools",
    component: HeaderAsideLayout,
    children: [{
        path: "/tools/toolsAudit",
        component: toolsAudit,
        name: "toolsAudit"
      },
      {
        path: "/tools/authorization",
        component: authorization,
        name: "authorization"
      },
      {
        path: "/tools/analysisSuccess",
        component: analysisSuccess,
        name: "analysisSuccess"
      },
      {
        path: "/tools/toolsAuditPWD",
        component: toolsAuditPWD,
        name: "toolsAuditPWD"
      },
    ]
  },

  //oem
  //oem
  // V1
  {
    path: '/oem/:accessToken/:uuid/:zq?',
    name: 'oemGuard',
    component: () =>
      import("@/pages/oem/OemGuard"),
    props: true,
    children: [
      {
        path: 'auth', ///:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?
        component: () =>
          import('@/pages/oem/UploadTable'),
        name: 'UploadTable-oem',
        hidden: true,
        props: true,
      },
      //查看第三方表格
      {
        path: 'view-tables/:dataPeriod',
        name: 'view-oem-tables',
        component: () => import('@/pages/oem/ViewTables'),
        props: route => ({
          ...route.params
        })
      },
      //查看报表
      //发票数据采集后的报告生成页面
      {
        path: 'InvoiceUpload',
        component: () =>
          import('@/pages/oem/invoiceUpload/invoiceUpload'),
        name: 'test-InvoiceUpload-oem',
        props: true
      },
      //查看报告
      {
        path: 'result',
        component: () =>
          import('@/pages/oem/OrmResult'),
        name: 'result-oem',
        hidden: true,
        props: true,
      },
    ],
  },
  // V2
  {
    path: '/oemV2/:zq?',
    name: 'oemGuardV2',
    component: () =>
      import("@/pages/oem/OemGuardV2"),
    props: true,
    children: [
      //查看报表
      {
        path: 'auth', ///:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?
        component: () =>
          import('@/pages/oem/UploadTable'),
        name: 'UploadTable-oem-v2',
        hidden: true,
        props: true,
      }
    ],
  },
  // 分享链接版本
  {
    path: '/oemV3',
    name: 'oemGuardV3',
    component: () =>
      import("@/pages/oem/OemGuardV2"),
    props: true,
    children: [
      //查看报表
      {
        path: 'auth', ///:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?
        component: () =>
          import('@/pages/oem/UploadTable'),
        name: 'UploadTable-oem-v3',
        hidden: true,
        props: true,
      },
      {
        path: 'InvoiceUploadV2',
        component: () =>
          import('@/pages/oem/invoiceUpload/invoiceUploadV2'),
        name: 'test-InvoiceUpload-oemV2',
        props: true
      },
    ],
  },
  //第三方王总授权
  {
    path: '/oauth',
    name: 'oemGuardV4',
    component: () =>
      import("@/pages/openNew/index"),
    props: true,
  },
  {
    path: '/oauthV2',
    name: 'oemGuardV4_2',
    component: () =>
      import("@/pages/openNew/index"),
    props: true,
  },
  {
    path: '/oauthV3',
    name: 'oemGuardV4_3',
    component: () =>
      import("@/pages/openNew/index"),
    props: true,
  },
  {
    path: '/oauth/success',
    name: 'oemGuardV4Success',
    component: () =>
      import("@/pages/openNew/success"),
    props: false,
    children: [
     
    ],
  },
  //简版pdf结果编辑器
  {
    path: "/simpleResultEditor/:action/:uuid/:nsrsbh",
    component: SimpleResultEditor,
    name: "SimpleResultEditor",
    hidden: true
  },
  //pdfFrontGenerator
  {
    path: "/pdfFrontGenerator/:from/:uuid/:nsrsbh",
    component: () =>
      import("@/pages/pdfFrontGenerator/PdfResult"),
    name: "pdf",
    hidden: true
  },
  {
    path: "/wordResultFrontGenerator/:from/:uuid/:nsrsbh",
    component: () =>
      import("@/pages/pdfFrontGenerator/wordResult"),
    name: "wordResult",
    hidden: true
  },
  //pdfFrontGenerator
  {
    path: "/pdfResult/:uuid/:nsrsbh",
    component: PdfResult,
    name: "pdfResult",
    hidden: true
  },
  //pdf结果编辑器详情
  {
    path: "/resultDetail/:nsrsbh/:uuid/:action/:type/:contrastKey?/:indexes",
    component: ResultDetail,
    name: "resultDetail",
    hidden: true,
    props: true
  },
  //免费版介绍
  {
    path: "/free",
    component: () =>
      import("@/pages/FreeEdition/FreeEditionIntroduction"),
    name: "freeEditionIntroduction"
  },
  {
    path: "/passport",
    component: Login,
    name: "",
    hidden: true,
    children: [{
        path: "login",
        component: loginBase,
        name: "login"
      },
      {
        path: "register",
        component: registerBase,
        name: "register"
      },
      {
        path: "wxlogin",
        component: wxlogin,
        name: "wxlogin"
      },
      {
        path: "forgetPassword",
        component: forgetPassword,
        name: "forgetPassword"
      }
    ]
  },
  {
    path: "/table",
    component: HeaderAsideLayout,
    children: [{
        path: "/table/basic",
        component: NotFound
      },
      {
        path: "/table/fixed",
        component: NotFound
      }
    ]
  },

  {
    path: "/admin/view-uploaded-tables/:bankId/:version/:nsrsbm/:zq/:uuid/:dataPeriod",
    name: "AdminUploadedTables",
    component: () =>
      import('@/pages/admin/AdminUploadedTables'),
    props: true
  },
  {
    path: "/admin/test/product/:uuid/:productID/:productName/manual-list/:zq/:zqLocation/fill/:templateID/:from",
    name: "admin-manual-fill-table",
    component: ManualFill,
    props: true
  },
  {
    path: "/test",
    component: HeaderAsideLayout,
    children: [{
        path: "/test/products",
        component: MyTest,
        name: "test-hub"
      },
      {
        path: "/test/product/:uuid/:productID/:productName/:version/:zqLocation?/:isShanDong",
        name: "choose-table",
        component: ChooseTable,
        props: true
      },
      {
        path: "/test/product/:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:isShanDong/:deleteDataType?",
        name: "upload-table",
        component: UploadTable,
        props: true
      },
      {
        path: "/test/product/:uuid/:productID/:productName/manual-list/:zq/:zqLocation",
        name: "upload-table-manual",
        component: ManualTableList,
        props: true
      },
      {
        path: "/test/product/:uuid/:productID/:productName/manual-list/:zq/:zqLocation/fill/:templateID/:from",
        name: "manual-fill-table",
        component: ManualFill,
        props: true
      },
      {
        path: "/test/Balance/:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:userName?/:nsrsbhIndex?/:qykjzdIndex?/:isShanDong/:grantIndex?",
        component: Balance,
        name: "test-Balance",
        props: true
      },
      {
        path: "/test/invoice/:uuid/:productID/:productName/upload-list/:zq/:zqLocation/:dataPeriod/:version/:user/:userName?/:isShanDong",
        component: Invoice,
        name: "test-invoice",
        props: true
      },
      {
        path: "/test/InvoiceUpload/:version",
        component: InvoiceUpload,
        name: "test-InvoiceUpload",
        props: true
      },
      {
        path: "/util/yinHuaShui/:programId",
        component: () =>
          import("@/pages/util/yinHuaShui/YinHuaShui"),
        name: "util-yinHuaShui",
        props: true
      },
      {
        path: "/util/yinHuaShuiUploader",
        component: () =>
          import("@/pages/util/yinHuaShui/YinhuaShuiUploader"),
        name: "util-yinHuaShui-uploader",
        props: true
      },
      {
        path: "/test/test-log",
        component: TestLog,
        name: "test-log"
      },
      {
        path: "/test/TestReports",
        component: TestReports
      },
      {
        path: "/test/test-noData",
        component: noData,
        name: "test-noData"
      }
    ]
  },
  {
    path: "/setUser",
    component: HeaderAsideLayout,
    children: [{
      path: "/setUser",
      component: UserSet,
      name: "setUser"
    }]
  },
  {
    path: "/setUserCompany",
    component: HeaderAsideLayout,
    children: [{
      path: "/setUserCompany",
      component: setUserCompany,
      name: "setUserCompany"
    }]
  },
  //企业认证
  {
    path: "/companyApply",
    component: HeaderAsideLayout,
    children: [{
      path: "/companyApply",
      component: companyApply,
      name: "companyApply"
    }]
  },

  //小工具
  //企业认证
  {
    path: "/util",
    component: HeaderAsideLayout,
    children: [{
        path: "/util/abnormal_tax",
        component: abnormal_tax,
        name: "abnormal_tax"
      },
      {
        path: "/util/individual_tax",
        component: individual_tax,
        name: "individual_tax"
      },
      {
        path: "/util/calculator_tax",
        component: calculator_tax,
        name: "calculator_tax"
      },
      {
        path: "/util/abnormal_tax/abnormal_msg",
        name: "abnormal_msg",
        component: abnormal_msg
      }
    ]
  },
  //消息中心
  {
    path: "/message",
    component: HeaderAsideLayout,
    children: [{
      path: "/message/center",
      component: message
    }]
  },
  //帮助中心
  {
    path: "/helpCenter",
    component: helpCenter
  },
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
        path: "/dashboard/analysis",
        component: Dashboard
      },
      {
        path: "/dashboard/monitor",
        component: NotFound
      },
      {
        path: "/dashboard/workplace",
        component: NotFound
      }
    ]
  },
  //产品中心
  {
    path: "/",
    component: HeaderAsideLayout,
    children: [{
        path: "/product/discount",
        name: "discount",
        component: productsDiscount
      },
      {
        path: "/product/record",
        name: "order",
        component: productsOrder
      },
      {
        path: "/product/goods",
        name: "goods",
        component: productsGoods
      },
      {
        path: "/product/submit",
        name: "submit",
        component: productsSubmit
      },
      {
        path: "/product/pay",
        name: "pay",
        component: productsPay
      },
      {
        path: "/product/info",
        name: "info",
        component: productsInfo
      },
      {
        path: "/product/agent",
        name: "agent",
        component: productsAgent
      },
      {
        path: "/product/goodsInfo",
        name: "goodsInfo",
        component: goodsInfo
      },
      {
        path: "/product/success",
        name: "paySuccess",
        component: paySuccess
      }
    ]
  },
  {
    path: "/product/discount",
    component: productsDiscount
  },
  //第三方登录用的页面
  {
    path: "/3rdPartAuth/:mobile",
    name: "thirdPartAuth",
    component: thirdPartAuth
  },
  //第三方首次登录用的页面
  {
    path: "/thirdpartregister",
    component: Login,
    name: "",
    hidden: true,
    children: [{
      path: "/thirdpartregister",
      name: "thirdPartRegister",
      component: thirdPartRegister
    }]
  },
  {
    path: "*",
    component: NotFound
  }
];

export default routerConfig;
