import axios,{get,post} from "@/axios";
import { Header } from "element-ui";

const base = process.env.VUE_APP_API_ROOT;
const basePlat = `${base}/open/console`
const daqcUrl = `${base}/daqc`

//通过搜索企业名称获取税号和授权信息
export const searchAuthSourceMsg = async ({ keyword}) => {
  const url = `${basePlat}/commonInspect/searchAuthSourceMsg`;
  const res = await get(url, { keyword });
  return res.data;
};

//授权并发送验证码（如果需要发送验证码）
export const authAndSendCode = async (params) => {
  const url = `${basePlat}/commonAuth/v2/login`;
  const res = await post(url, params);
  return res.data;
};

//授权并发送验证码（如果需要发送验证码）
export const authByCode = async (params) => {
  const url = `${basePlat}/commonAuth/v2/loginByCode`;
  const res = await post(url, params);
  return res.data;
};


//暂存三方授权前置信息
export const savePrepare = async (params) => {
  const url = `${basePlat}/commonInspect/savePrepare`;
  const res = await post(url, params);
  return res.data;
};


//二方专用-通过登录token换取授权token
export const getAuthToken = async ({ appTypeName,authorization}) => {
  const url = `${daqcUrl}/auth/middleware/getAuthToken`;
  const res = await get(url, { appTypeName,authorization });
  return res.data;
};



//二方专用-保存授权企业信息
export const savePrepareFromSecond = async (params) => {
  
  const url = `${daqcUrl}/auth/middleware/savePrepare`;
  const res = await post(url, params);
  return res.data;
};



//获取预授权信息
export const getPrepareInfo = async ({ programId}) => {
  const url = `${daqcUrl}/auth/getPrepareInfo`;
  const res = await get(url, { programId });
  return res.data;
};


//二方专用-授权并发送验证码（如果需要发送验证码）
export const authAndSendCodeFromSecond = async (params) => {
  
  const url = `${daqcUrl}/auth/middleware/authAndSendCode`;
  const res = await post(url, params);
  return res.data;
};


//二方专用-授权并发送验证码（如果需要发送验证码）
export const authByCodeFromSecond = async (params) => {
  
  const url = `${daqcUrl}/auth/middleware/authByCode`;
  const res = await post(url, params);
  return res.data;
};


//分享链接的唯一标识兑换分享token同时获取授权信息
export const getSharedPrepareInfo = async ({ uniqueCode}) => {
  const url = `${daqcUrl}/auth/getSharedPrepareInfo`;
  const res = await get(url, { uniqueCode });
  return res;
};


//第三方重新发送验证码
export const reSendCode = async (params) => {
  
  const url = `${basePlat}/console/commonAuth/v2/sendCode`;
  const res = await post(url, params);
  return res.data;
};


//二方专用-重新发送验证码
export const reSendCodeFromSecond = async (params) => {
  
  const url = `${daqcUrl}/auth/middleware/sendCode`;
  const res = await post(url, params);
  return res.data;
};

